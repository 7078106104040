.custom-calendar {
  width: 100%;
  max-width: 100%;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.custom-calendar.react-calendar {
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.custom-calendar.react-calendar__navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #f3f4f6;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.custom-calendar.react-calendar__navigation button {
  background: none;
  border: none;
  font-size: 1rem;
  color: #007bff;
  cursor: pointer;
  transition: background 0.2s ease;
}

.custom-calendar.react-calendar__navigation button:hover {
  background-color: #e5f0ff;
  border-radius: 4px;
}

.custom-calendar.react-calendar__tile {
  text-align: center;
  padding: 0.5rem;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  transition: background 0.2s ease, transform 0.2s ease;
}

.custom-calendar.react-calendar__tile--now {
  background-color: #e0f7fa;
  color: #007bff;
  font-weight: bold;
}

.custom-calendar.react-calendar__tile--active {
  background-color: #007bff;
  color: white;
  transform: scale(1.1);
}

.custom-calendar.react-calendar__tile:hover {
  background-color: #e5f0ff;
  transform: scale(1.05);
}

.custom-calendar.react-calendar__month-view__weekdays {
  text-align: center;
  font-weight: bold;
  color: #6b7280;
  padding: 0.5rem 0;
  background-color: #f9fafb;
}

.custom-calendar.react-calendar__month-view__weekdays__weekday {
  font-size: 0.875rem;
  color: #6b7280;
}

.custom-calendar.react-calendar__tile--disabled {
  background-color: #f3f4f6;
  color: #d1d5db;
}

.custom-calendar.react-calendar__month-view__days__day--disabled {
  color: #d1d5db;
}

.custom-calendar.react-calendar__navigation__prev-button,
.custom-calendar.react-calendar__navigation__next-button {
  font-size: 1.25rem;
  color: #4b5563;
}

.custom-calendar.react-calendar__navigation__prev-button:hover,
.custom-calendar.react-calendar__navigation__next-button:hover {
  background-color: #e5e7eb;
  border-radius: 50%;
}

@media (max-width: 640px) {
  .custom-calendar.react-calendar__tile {
    font-size: 0.75rem;
  }

  .custom-calendar.react-calendar__navigation {
    flex-direction: column;
    align-items: flex-start;
  }

  .custom-calendar.react-calendar__navigation button {
    font-size: 1.125rem;
  }
}
