@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Big+Shoulders+Text:wght@100..900&family=Oswald:wght@200..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Big+Shoulders+Text:wght@100..900&family=Oswald:wght@200..700&family=Roboto:ital,wght@0,100..900;1,100..900&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: AgencyFb;
  src: url("../public/Fonts/AgencyFB-Bold.ttf") format("truetype"),
    url("../public/Fonts/AgencyFB-Bold.woff") format("woff"),
    url("../public/Fonts/AgencyFB-Bold.woff2") format("woff2");
}

.react-tel-input .selected-flag:hover {
  background-color: #4ade80 !important;
  transition: background-color 0.3s ease;
}

.react-tel-input .selected-flag {
  background-color: black !important;
  border: 1px solid rgba(255, 255, 255, 0.818);
}

#contact .react-tel-input .selected-flag {
  background-color: transparent !important;
  border: 0px;
}

#contact .react-tel-input .form-control {
  border-bottom: 1px solid #4b5563;
}
#contact .react-tel-input .form-control:focus {
  border: 1px solid #34d399;
}

#contact .react-tel-input .selected-flag:hover {
  background-color: #4ade80 !important;
  transition: background-color 0.3s ease;
}

.react-tel-input .highlight {
  background-color: #4ade80 !important;
}
.react-tel-input .country:hover {
  background-color: #3e91ea !important;
}

.react-tel-input .country-list {
  scrollbar-width: thin;
  scrollbar-color: #4ade80 transparent;
}

.react-tel-input .country-list::-webkit-scrollbar {
  width: 8px;
}

.react-tel-input .country-list::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.react-tel-input .country-list::-webkit-scrollbar-thumb {
  background-color: rgba(34, 197, 94, 0.5);
  border-radius: 10px;
  border: 2px solid transparent;
}
.react-tel-input .country-list::-webkit-scrollbar-thumb:hover {
  background-color: rgba(34, 197, 94, 0.7);
}
